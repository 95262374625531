import React from "react";

import { Col, Row } from "antd";

import { ContentBlock, QuickLink, PictureTile } from "../../atoms";
import Picture from "../../picture/Picture";

import { ReactComponent as AcademyIcon } from "../../../static/svg/academy.svg";
import DefaultProductImage from "../../../static/images/default-product-image.jpg";

import KnowledgeAndExpertiseSmall from "../../../static/images/seminars/warenwissen-und-fachkompetenz-@x1.png";
import KnowledgeAndExpertiseLarge from "../../../static/images/seminars/warenwissen-und-fachkompetenz-@x2.png";
import OrganicFoodQualificationSmall from "../../../static/images/seminars/zusatzqualifikation-naturkost-@x1.png";
import OrganicFoodQualificationLarge from "../../../static/images/seminars/zusatzqualifikation-naturkost-@x2.png";
import EffectiveManagementSmall from "../../../static/images/seminars/effektive-geschaeftsfuehrung-@x1.png";
import EffectiveManagementLarge from "../../../static/images/seminars/effektive-geschaeftsfuehrung-@x2.png";
import ActiveServiceAndSalesSmall from "../../../static/images/seminars/aktiver-service-und-verkauf-@x1.png";
import ActiveServiceAndSalesLarge from "../../../static/images/seminars/aktiver-service-und-verkauf-@x2.png";

interface Seminar {
  icon: React.ReactNode;
  title: string;
  imageSmall: string;
  imageLarge: string;
  href: string;
  label: string;
}

const SeminarWidget: React.FC = () => {
  const seminars: Seminar[] = [
    {
      icon: <AcademyIcon />,
      title: "Azubis werden Profis",
      imageSmall: OrganicFoodQualificationSmall,
      imageLarge: OrganicFoodQualificationLarge,
      href: "https://www.weiling-akademie.de/zqn",
      label: "Mehr erfahren",
    },
    {
      icon: <AcademyIcon />,
      title: "Mehr Warenwissen und Fachkompetenz",
      imageSmall: KnowledgeAndExpertiseSmall,
      imageLarge: KnowledgeAndExpertiseLarge,
      href: "https://www.weiling-akademie.de/seminare-2024?tx_wseminare_plg%5Bcontroller%5D=Seminar&tx_wseminare_plg%5Bsemkat%5D=2",
      label: "Jetzt buchen",
    },
    {
      icon: <AcademyIcon />,
      title: "Aktiver Service und Verkauf",
      imageSmall: ActiveServiceAndSalesSmall,
      imageLarge: ActiveServiceAndSalesLarge,
      href: "https://www.weiling-akademie.de/seminare-2024?tx_wseminare_plg%5Bcontroller%5D=Seminar&tx_wseminare_plg%5Bsemkat%5D=4",
      label: "Jetzt buchen",
    },
    {
      icon: <AcademyIcon />,
      title: "Effektive Geschäftsführung",
      imageSmall: EffectiveManagementSmall,
      imageLarge: EffectiveManagementLarge,
      href: "https://www.weiling-akademie.de/seminare-2024?tx_wseminare_plg%5Bcontroller%5D=Seminar&tx_wseminare_plg%5Bsemkat%5D=5",
      label: "Jetzt buchen",
    },
  ];

  return (
    <ContentBlock
      className="seminar-widget"
      title="Weiterbildung in der Akademie"
      linkHref="https://www.weiling-akademie.de/"
      linkText="Mehr anzeigen"
      showLink
    >
      <Row gutter={[10, 10]}>
        {seminars.map((seminar: Seminar) => (
          <Col key={seminar.title} xs={12} md={6} lg={3}>
            <PictureTile
              icon={seminar.icon}
              title={seminar.title}
              picture={
                <Picture
                  imageScreenXs={seminar.imageSmall}
                  imageScreenSm={seminar.imageLarge}
                  fallbackImage={DefaultProductImage}
                />
              }
              link={
                <QuickLink
                  label={seminar.label}
                  href={seminar.href}
                  linkType="primary--inverted"
                />
              }
            />
          </Col>
        ))}
      </Row>
    </ContentBlock>
  );
};

export default SeminarWidget;
