import React from "react";

import clsx from "clsx";
import { Spin } from "antd";

interface LoaderComponentProps {
  className?: string;
}

const LoaderComponent: React.FC<LoaderComponentProps> = (
  props: LoaderComponentProps
) => {
  const { className } = props;

  return (
    <div className={clsx("loader-component", className)}>
      <Spin className="loader-component__spin" size="large" color="white" />
    </div>
  );
};

export default LoaderComponent;
