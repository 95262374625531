import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Layout } from "antd";
import axios, { CancelTokenSource } from "axios";
import queryString from "query-string";

import getCancelTokenSource, {
  cancelAndRenewCancelToken,
} from "../../../api/getCancelTokenSource";
import useCancelAxiosOnUnmount from "../../../hooks/useCancelAxiosOnUnmount";
import getAllCartsDataByDate from "../../../api/cart/getAllCartsDataByDate";
import getProductsData from "../../../api/products/getProductsData";
import setAlreadyOrdered from "../../../state/actions/setAlreadyOrdered";
import useShouldWeekplannerBeVisible from "../../../hooks/useShouldWeekplannerBeVisible";
import TrackingHelmet from "../../Matomo/TrackingHelmet";
import requestCatchHandler from "../../../api/requestCatchHandler";
import useGetWeekplannerRequestDates from "../../../hooks/useGetWeekplannerRequestDates";
import WeekplannerItemsHeader from "./WeekplannerItemsHeader";
import WeekplannerItems from "./WeekplannerItems";
import WeekplannerUnsupportedDevice from "./WeekplannerUnsupportedDevice";
import { getFilterStatesWithoutDefaults } from "../../../api/products/getProductsFilters";
import WeekplannerHeader from "./WeekplannerHeader";
import {
  locationSearchQueryParameter,
  pageTitles,
  pageTitleSuffix,
} from "../../../appConfig";
import { productAttributes } from "../../../api/productAttributes";
import { ProductData } from "../../../types/productData";
import { WeekplannerItemData } from "../../../types/weekplanner";
import { RootState } from "../../../types/rootState";

interface WeekplannerProps {
  className?: string;
}

const HEADER_SCROLL_OFFSET = 800;
const HEADER_MIN_PRODUCT_COUNT = 20;
const DEFAULT_MODE_ITEM_HEIGHT = 80;
const SIMPLE_MODE_ITEM_HEIGHT = 62;
const GROUP_HEADER_ITEM_HEIGHT = 25;

const Weekplanner: React.FC<WeekplannerProps> = (props: WeekplannerProps) => {
  const { className } = props;

  const { categoryKey } = useParams<{
    categoryKey?: string;
  }>();

  const { search } = useLocation();
  const dispatch = useDispatch();
  const shouldWeekplannerBeVisible = useShouldWeekplannerBeVisible();
  const { cartsRequestDates, productsRequestDates: itemsRequestDates } =
    useGetWeekplannerRequestDates();

  const parsedSearch = queryString.parse(search);
  const {
    [locationSearchQueryParameter.sortBy]: sortBy,
    [locationSearchQueryParameter.sortDirection]: sortDirection,
    [locationSearchQueryParameter.searchTerm]: searchQuery,
  } = parsedSearch;

  const { weekplannerUseSimpleMode: useSimpleMode = false } = useSelector(
    (state: RootState) => state?.userData || {}
  );
  const { companyBusinessUnitKey } = useSelector(
    (state: RootState) => state?.userData?.businessUnit || {}
  );
  const orderItemIndexVersion = useSelector(
    (state: RootState) => state?.alreadyOrdered?.version || {}
  );

  const [items, setItems] = useState<Array<ProductData>>([]);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [paginationData, setPaginationData] = useState<Record<string, any>>({
    currentPage: 1,
    numFound: 0,
  });
  const [searchVersion, setSearchVersion] = useState<string>(
    orderItemIndexVersion
  );

  const shouldReloadCartsRef = useRef<boolean>(true);
  const cancelTokenSource = useRef<CancelTokenSource>(getCancelTokenSource());
  useCancelAxiosOnUnmount(cancelTokenSource.current);

  const doLoadMoreItems = useCallback(() => {
    cancelTokenSource.current = cancelAndRenewCancelToken(
      cancelTokenSource.current
    );

    setIsLoading(true);

    const cartsRequest =
      shouldReloadCartsRef?.current === true
        ? getAllCartsDataByDate(cartsRequestDates, cancelTokenSource.current)
        : Promise.resolve();

    const itemsRequest = getProductsData({
      page,
      categoryKey,
      deliveryDates: itemsRequestDates,
      requestFilters: getFilterStatesWithoutDefaults(),
      cancelTokenSource: cancelTokenSource.current,
      sortBy: sortBy ? String(sortBy) : undefined,
      sortDirection: sortDirection ? String(sortDirection) : undefined,
      query: searchQuery ? String(searchQuery) : undefined,
    });

    Promise.all([cartsRequest, itemsRequest])
      .then(([cartsResponse, itemsResponse]) => {
        setIsLoading(false);

        if (cartsResponse !== undefined) {
          shouldReloadCartsRef.current = false;
          dispatch({
            type: "weekplanner/set-cart",
            payload: cartsResponse,
          });
        }

        if (itemsResponse) {
          const {
            pagination,
            products = [],
            version: catalogSearchVersion,
          } = itemsResponse;
          const {
            currentPage = 1,
            numFound = 0,
            maxPage = 1,
          } = pagination || {};

          setItems((prevProducts) => [...prevProducts, ...products]);
          setPaginationData({ currentPage, numFound });
          setPage(products?.length ? currentPage + 1 : currentPage);
          setHasMoreItems(products?.length ? currentPage < maxPage : true);
          setSearchVersion(catalogSearchVersion);
        }
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
        }

        requestCatchHandler(error);
      });
  }, [
    cartsRequestDates,
    categoryKey,
    dispatch,
    itemsRequestDates,
    page,
    shouldReloadCartsRef,
    sortBy,
    sortDirection,
    searchQuery,
  ]);

  const weekplannerItems = useMemo((): Array<WeekplannerItemData> => {
    const itemData: Array<WeekplannerItemData> = [];

    items.forEach(
      (product: ProductData, i: number, products: Array<ProductData>) => {
        const attribute = productAttributes.weekplannerGroupHeadline;
        const prevHeadline = products?.[i - 1]?.attributes?.[attribute] || "";
        const currHeadline = product?.attributes?.[attribute] || "";

        const shouldAddHeader = prevHeadline !== currHeadline && currHeadline;

        if (shouldAddHeader) {
          itemData.push({
            type: "header",
            data: currHeadline,
            size: GROUP_HEADER_ITEM_HEIGHT,
          });
        }

        itemData.push({
          type: "item",
          data: product,
          size: useSimpleMode
            ? SIMPLE_MODE_ITEM_HEIGHT
            : DEFAULT_MODE_ITEM_HEIGHT,
          className: i % 2 === 0 ? "even" : "odd",
        });
      }
    );

    return itemData;
  }, [items, useSimpleMode]);

  const loadMoreItems = isLoading || !hasMoreItems ? () => {} : doLoadMoreItems;

  const resetState = () => {
    setHasMoreItems(true);
    setPage(1);
    setItems([]);
  };

  useEffect(() => {
    resetState();
  }, [categoryKey, search]);

  useEffect(() => {
    shouldReloadCartsRef.current = true;
    resetState();
  }, [companyBusinessUnitKey]);

  useEffect(() => {
    if (searchVersion === null || searchVersion === orderItemIndexVersion) {
      return;
    }

    if (searchVersion === "0") {
      dispatch({
        type: "alreadyOrdered/empty-order-item-index",
      });
      return;
    }

    setAlreadyOrdered({
      cancelTokenSource: cancelTokenSource.current,
    }).catch((error) => {
      requestCatchHandler(error);
    });
  }, [dispatch, orderItemIndexVersion, searchVersion]);

  return (
    <>
      <TrackingHelmet title={pageTitles.weekPlanner} suffix={pageTitleSuffix} />

      <div
        className={clsx(
          "weekplanner-new",
          !shouldWeekplannerBeVisible && "weekplanner-new--not-visible",
          className
        )}
      >
        <Layout className="container-layout container-layout--inner">
          <WeekplannerHeader
            categoryKey={categoryKey}
            productItems={items}
            paginationData={paginationData}
            isLoading={isLoading}
            sortBy={sortBy ? String(sortBy) : undefined}
            sortDirection={sortDirection ? String(sortDirection) : undefined}
            searchQuery={searchQuery ? String(searchQuery) : undefined}
            isExpanded={isHeaderVisible}
          />

          {shouldWeekplannerBeVisible && (
            <WeekplannerItemsHeader
              onPaginate={() => {
                shouldReloadCartsRef.current = true;
              }}
            />
          )}

          {shouldWeekplannerBeVisible && (
            <WeekplannerItems
              hasNextPage={hasMoreItems}
              isNextPageLoading={isLoading}
              items={weekplannerItems}
              loadNextPage={loadMoreItems}
              categoryKey={categoryKey}
              isHeaderVisible={isHeaderVisible}
              onScroll={(scrollProps) => {
                const direction = scrollProps.scrollDirection;
                const offset = scrollProps.scrollOffset;
                const shouldHideHeader =
                  items?.length > HEADER_MIN_PRODUCT_COUNT &&
                  direction === "forward" &&
                  offset >= HEADER_SCROLL_OFFSET;
                const shouldShowHeader =
                  items?.length > HEADER_MIN_PRODUCT_COUNT &&
                  direction === "backward" &&
                  offset === 0;

                if (shouldShowHeader) {
                  setIsHeaderVisible(true);
                }
                if (shouldHideHeader) {
                  setIsHeaderVisible(false);
                }
              }}
              defaultItemSize={
                useSimpleMode
                  ? SIMPLE_MODE_ITEM_HEIGHT
                  : DEFAULT_MODE_ITEM_HEIGHT
              }
            />
          )}

          {!shouldWeekplannerBeVisible && <WeekplannerUnsupportedDevice />}
        </Layout>
      </div>
    </>
  );
};

export default Weekplanner;
