import React, { BaseSyntheticEvent } from "react";

import clsx from "clsx";
import { Button, Card, Col, Divider, Row, Space, Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { Entry } from "contentful";
import { useNavigate } from "react-router-dom";

import downloadContenfulFile from "../../../../utils/downloadContenfulFile";
import isExternalUrl from "../../../../utils/isExternalUrl";
import getRelativeFromAbsoluteUrl from "../../../../utils/getRelativeFromAbsoluteUrl";
import { getTargetFromAsset, getTooltipTextFromAsset } from "../utils";
import { ContentfulImpulsaktionTeaserEntry } from "../../../../types/contentfulImpulsaktion";
import { ReactComponent as OrderIcon } from "../../../../static/svg/my-order.svg";
import { ReactComponent as DownloadAlt2Icon } from "../../../../static/svg/download-alternative-2.svg";

interface ImpulseTeaserProps {
  title: string;
  teasers: Array<Entry<ContentfulImpulsaktionTeaserEntry>>;
  className?: string;
  variant?: "small";
  colProps?: object;
}

const ImpulseTeaser: React.FC<ImpulseTeaserProps> = (
  props: ImpulseTeaserProps
) => {
  const {
    title,
    teasers,
    className,
    variant = "",
    colProps = { xs: 12, md: 6, xl: 3 },
  } = props;

  const navigate = useNavigate();

  if (teasers?.length < 1) {
    return null;
  }

  return (
    <div
      className={clsx(
        "impulse-teaser",
        variant && `impulse-teaser--${variant}`,
        className
      )}
    >
      <h2>{title}</h2>
      <Divider />
      <Row gutter={[40, 30]}>
        {teasers?.map(
          (teaser: Entry<ContentfulImpulsaktionTeaserEntry>, i: number) => {
            const isExternalLink = isExternalUrl(teaser?.fields?.link);

            return (
              <Col key={`impulse-teaser_${teaser?.sys?.id}_${i}`} {...colProps}>
                <Card
                  cover={
                    <img
                      src={teaser?.fields?.image?.fields?.file?.url}
                      alt={teaser?.fields?.headline}
                    />
                  }
                >
                  <h2>{teaser?.fields?.headline}</h2>
                  <p className="impulse-teaser__description">
                    {teaser?.fields?.description}
                  </p>
                  <Space>
                    {!!teaser?.fields?.link && !isExternalLink && (
                      <Button
                        className="button buttonSecondary width-full"
                        icon={<OrderIcon />}
                        onClick={() =>
                          navigate(
                            getRelativeFromAbsoluteUrl(teaser?.fields?.link)
                          )
                        }
                      >
                        {teaser?.fields?.link_text || ""}
                      </Button>
                    )}

                    {!!teaser?.fields?.link && isExternalLink && (
                      <Button
                        className="button buttonSecondary width-full"
                        icon={<LinkOutlined />}
                        href={teaser?.fields?.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {teaser?.fields?.link_text || ""}
                      </Button>
                    )}

                    {!!teaser?.fields?.download_file?.fields?.file?.url && (
                      <Tooltip
                        title={getTooltipTextFromAsset(
                          teaser?.fields?.download_file
                        )}
                      >
                        <Button
                          className="button buttonPrimary width-full"
                          icon={<DownloadAlt2Icon />}
                          onClick={(e: BaseSyntheticEvent) =>
                            downloadContenfulFile(
                              e,
                              teaser.fields.download_file,
                              getTargetFromAsset(teaser?.fields?.download_file)
                            )
                          }
                        >
                          {teaser?.fields?.download_cta || ""}
                        </Button>
                      </Tooltip>
                    )}
                  </Space>
                </Card>
              </Col>
            );
          }
        )}
      </Row>
    </div>
  );
};

export default ImpulseTeaser;
