import React from "react";

import "react-app-polyfill/stable";

import { createRoot } from "react-dom/client";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import { I18nextProvider } from "react-i18next";
import { ConfigProvider, message } from "antd";
import de_DE from "antd/es/locale/de_DE";
import en_GB from "antd/es/locale/en_GB";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Locale } from "antd/lib/locale-provider";

import {
  Imprint,
  Privacy,
  LoginRequest,
  NotFound,
  Terms,
} from "./components/pages";
import getTranslation from "./utils/getTranslation";
import App from "./App";
import UpdatePassword from "./components/userAuth/passwordScreens/UpdatePassword";
import Maintenance from "./components/Maintenance/Maintenance";
import Login from "./components/userAuth/Login";
import i18n from "./i18n";
import instanceConfig from "./matomoConfig";
import { store, persistor } from "./store";
import { routePathNames } from "./appConfig";
import ProtectedRoute from "./Router/ProtectedRoute";
import * as serviceWorker from "./serviceWorker";

import "./styles.less";

Sentry.init({
  debug: false,
  dsn: process.env?.REACT_APP_SENTRY_DNS,
  integrations: [],
});

/* eslint-disable-next-line */
const t = getTranslation;

declare global {
  interface LangMap {
    [key: string]: Locale;
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      [elemName: string]: any;
    }
    interface IntrinsicAttributes {
      [elemName: string]: any;
    }
  }
}

const matomoInstance = createInstance(instanceConfig);

const myLanguages: LangMap = {
  "de-DE": de_DE,
  "en-GB": en_GB,
};

const appConfig = {
  locales: "de-DE",
};

const languageToUse = (appConfig && appConfig.locales) || "de-DE";

i18n.changeLanguage(languageToUse, (err, tFunction) => {
  // eslint-disable-next-line no-console
  if (err) {
    throw new Error(`Something went wrong loading: ${err}`);
  }

  tFunction("key"); // -> same as i18next.t
  return true;
});

/**
 * ant design message config
 * @see {@link https://ant.design/components/message/#API}
 */
message.config({
  duration: 3,
  maxCount: 3,
  top: 100,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <MatomoProvider value={matomoInstance}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <SentryRoutes>
              <Route path={routePathNames.login} element={<Login />} />

              <Route
                path={routePathNames.passwordReset}
                element={<UpdatePassword />}
              >
                <Route path=":token" element={<UpdatePassword />} />
              </Route>

              <Route
                path={routePathNames.confirmRegister}
                element={<UpdatePassword />}
              >
                <Route path=":token" element={<UpdatePassword />} />
              </Route>

              <Route
                path={routePathNames.loginRequest}
                element={<LoginRequest />}
              />

              <Route
                path={routePathNames.termsAndConditions}
                element={<Terms />}
              />

              <Route path={routePathNames.imprint} element={<Imprint />} />

              <Route path={routePathNames.privacy} element={<Privacy />} />

              <Route path={routePathNames.notFound} element={<NotFound />} />

              <Route
                path={routePathNames.maintenance}
                element={<Maintenance />}
              />

              {/* put protected routes at the end to act as a wildcard route fetcher */}
              <Route
                path="*"
                element={
                  <ProtectedRoute>
                    <ConfigProvider locale={myLanguages[languageToUse]}>
                      <App />
                    </ConfigProvider>
                  </ProtectedRoute>
                }
              />
            </SentryRoutes>
          </BrowserRouter>
        </I18nextProvider>
      </PersistGate>
    </MatomoProvider>
  </Provider>
  // Contextprovider does not work at the moment as they have an error there
);

if (window.Cypress) {
  window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
