import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "moment/locale/de";
import { Layout, Col, Row, Button } from "antd";
import { CancelTokenSource } from "axios";
import moment from "moment";
import clsx from "clsx";
import axiosWithAuth from "../../api/axiosWithAuth";
import { cartsEndpoint } from "../../api/endpoints";
import ShopCategoryNavigation from "../navigation/ShopCategoryNavigation";
import LoaderComponent from "../atoms/LoaderComponent";
import HrDivider from "../divider/Divider";
import useGlobalNetworkState from "../../hooks/useGlobalNetworkState";
import Note from "../note/Note";
import requestCatchHandler from "../../api/requestCatchHandler";
import MinimumOrderLimits from "./minimumOrderLimits/MinimumOrderLimits";
import CartItemsList from "./CartItemsList";
import CartDeleteModal from "./CartDeleteModal";
import CartCheckout from "./CartCheckout";
import { ProductData } from "../../types/productData";
import setCartAndUserListsToStore from "../../state/actions/setCartAndUserListsToStore";
import getCancelTokenSource from "../../api/getCancelTokenSource";
import useGetDeliveryDate from "../../hooks/useGetDeliveryDate";
import BackButton from "../backButton/BackButton";
import ButtonBackToTop from "../buttons/ButtonBackToTop";
import { RootState } from "../../types/rootState";
import { pageTitles, pageTitleSuffix } from "../../appConfig";
import useGetAllCartsCheapMetadata from "../../hooks/useGetAllCartsCheapMetadata";
import { ReactComponent as CartSVG } from "../../static/svg/cart.svg";
import useGetContactPerson from "../../hooks/useGetContactPerson";
import TrackingHelmet from "../Matomo/TrackingHelmet";

interface CartState {
  deliveryDate: string;
}

const Cart: React.FC = function Cart() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [deliveryDate] = useGetDeliveryDate();
  const contactPerson = useGetContactPerson();

  const urlDeliveryDate = useMemo(
    () => (location?.state as CartState)?.deliveryDate || deliveryDate,
    [deliveryDate, location]
  );

  const {
    id: reduxCartId,
    etag: currCartETag,
    cartNote,
    cartNoteKube,
  } = useSelector((state: RootState) => state.currentCartMetaData);
  const { cartItems, selectedCartItems } = useSelector(
    (state: RootState) => state.currentCart
  );
  const {
    termsAndConditions: { acceptedTimestamp },
  } = useSelector((state: RootState) => state?.userData || {});

  const { companyBusinessUnitKey: customerNumber } = useSelector(
    (state: RootState) => state?.userData?.businessUnit || {}
  );

  const { timestamp: termsAndConditionsTimestamp } = useSelector(
    (state: RootState) => state.termsAndConditions
  );

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState<boolean>(acceptedTimestamp > termsAndConditionsTimestamp);

  const [cartNoteIsUpdating, setCartNoteIsUpdating] = useState(false);
  const [componentLoading, setComponentLoading] = useGlobalNetworkState(
    "component",
    false
  );
  const [numUnavailableCartItemsErrors, setNumUnavailableCartItemsErrors] =
    useState<number>(0);

  const selectedItems: ProductData[] = useMemo(
    () =>
      cartItems.filter((cartItem: ProductData) =>
        selectedCartItems.includes(cartItem.id)
      ),
    [cartItems, selectedCartItems]
  );

  const selectedItemsTotal: number = useMemo(
    () =>
      selectedItems
        .map((cartItem: ProductData) => cartItem?.calculations?.sumPrice || 0)
        .reduce((sum: number, cur: number) => sum + cur, 0),
    [selectedItems]
  );

  const itemsTotal: number = useMemo(
    () =>
      cartItems
        .map((cartItem: ProductData) => cartItem?.calculations?.sumPrice || 0)
        .reduce((sum: number, cur: number) => sum + cur, 0),
    [cartItems]
  );

  // Retrieves all the info for every Cart the user has
  const getCurrentCart = useCallback(
    async (requestedDate: string, cancelTokenSource: CancelTokenSource) =>
      setCartAndUserListsToStore({
        deliveryDate: requestedDate,
        setComponentIsLoading: setComponentLoading,
        cancelTokenSource,
      }),
    [setComponentLoading]
  );

  /**
   * update cart note or cart note for client advisor (KuBe)
   * @param type {string} - based on the attribute naming of API
   * @param value {string}
   */
  const updateCartNoteWithType = ({
    type,
    value,
  }: {
    type: "cartNoteKube" | "cartNote";
    value: string;
  }) => {
    const attributes: Record<string, string> = {};
    const cartNoteType = type;
    attributes[cartNoteType] = value;

    setCartNoteIsUpdating(true);

    axiosWithAuth()
      .patch(
        `${cartsEndpoint}/${reduxCartId}`,
        {
          data: {
            type: "carts",
            attributes,
          },
        },
        {
          headers: {
            "If-Match": currCartETag,
          },
        }
      )
      .then((cartUpdateResponse) => {
        if (!cartUpdateResponse?.data?.data?.attributes) {
          Promise.reject(cartUpdateResponse);
        }

        dispatch({
          type: "cartMeta/set-new-cart-note",
          payload: {
            [cartNoteType]:
              cartUpdateResponse?.data?.data?.attributes[cartNoteType],
            etag: cartUpdateResponse?.headers?.etag,
          },
        });

        setCartNoteIsUpdating(false);

        return cartUpdateResponse;
      })
      .catch(requestCatchHandler);
  };

  /**
   * Handle unavailable cart items (=> increase error count)
   */
  const handleUnavailableCartItemsError = () => {
    setNumUnavailableCartItemsErrors(numUnavailableCartItemsErrors + 1);
  };

  const { openCarts } = useGetAllCartsCheapMetadata();
  const [showCartButton, setShowCartButton] = useState<boolean>(false);
  useEffect(() => {
    if (
      openCarts.length > 1 ||
      (openCarts.length === 1 &&
        !moment(openCarts?.[0]?.deliveryDate).isSame(deliveryDate))
    ) {
      setShowCartButton(true);
    }
  }, [deliveryDate, openCarts]);

  // fetch actual cart
  useEffect(() => {
    const cancelTokenSource = getCancelTokenSource();
    getCurrentCart(urlDeliveryDate, cancelTokenSource);

    return () => {
      cancelTokenSource.cancel();
    };
  }, [getCurrentCart, urlDeliveryDate, numUnavailableCartItemsErrors]);

  return (
    <>
      <TrackingHelmet title={pageTitles.cart} suffix={pageTitleSuffix} />

      {componentLoading && <LoaderComponent />}

      <ShopCategoryNavigation />

      <Layout className="container-layout container-layout--inner cart">
        <BackButton />

        <Row>
          <Col span={12}>
            <MinimumOrderLimits />
          </Col>
        </Row>

        <Row align="middle">
          <Col xs={12} flex="auto">
            <h1 className="cartTitle">Warenkorb</h1>

            <CartDeleteModal
              showCartButton={showCartButton}
              itemCount={cartItems?.length || 0}
            />
          </Col>
          <Button
            className={clsx(
              "cartButton",
              showCartButton ? "" : "hideCartButton"
            )}
            href="/warenkoerbe"
            icon={<CartSVG className="svgCartButton" />}
          >
            weitere Warenkörbe
          </Button>
        </Row>
        <HrDivider
          size={2}
          spacingTop="s"
          spacingBottom="m"
          className="divider--no-padding"
        />

        <Row justify="space-between" gutter={32}>
          <Col className="customer" xs={12} lg={7}>
            {customerNumber?.length && (
              <p className="mb-m text-medium">
                <span className="text-bold">Kundennr. </span>
                {customerNumber}
              </p>
            )}
            <Note
              title="Notiz"
              text={cartNote}
              onChange={(value: any) => {
                updateCartNoteWithType({ type: "cartNote", value });
              }}
              disabled={cartNoteIsUpdating}
              key="note"
            />
            <Note
              title={`Anmerkung für ${
                contactPerson?.name || "unsere Kundenbetreuung"
              }`}
              text={cartNoteKube}
              onChange={(value: any) => {
                updateCartNoteWithType({ type: "cartNoteKube", value });
              }}
              disabled={cartNoteIsUpdating || !contactPerson?.email}
              key="annotation"
            />
          </Col>

          <CartCheckout
            totalSelectedItems={selectedItems?.length || 0}
            selectedItemsTotal={selectedItemsTotal}
            termsAndConditionsAccepted={termsAndConditionsAccepted}
            onTermsAndConditionsChange={setTermsAndConditionsAccepted}
            onUnavailableCartItemsError={handleUnavailableCartItemsError}
            isAlwaysVisible
            disabled={cartNoteIsUpdating}
            totalItems={cartItems?.length || 0}
            itemsTotal={itemsTotal}
          />
        </Row>

        <HrDivider
          size={2}
          spacingTop="m"
          spacingBottom="m"
          className="divider--no-padding"
        />
      </Layout>

      <Layout className="container-layout container-layout--inner cart">
        <CartItemsList />

        <Row
          gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}
          className="cart-footer-row"
        >
          <Col xs={{ span: 12, offset: 0 }} lg={{ span: 5, offset: 7 }}>
            <CartCheckout
              totalSelectedItems={selectedItems?.length || 0}
              selectedItemsTotal={selectedItemsTotal}
              termsAndConditionsAccepted={termsAndConditionsAccepted}
              onTermsAndConditionsChange={setTermsAndConditionsAccepted}
              onUnavailableCartItemsError={handleUnavailableCartItemsError}
              isAlwaysVisible
              disabled={cartNoteIsUpdating}
              totalItems={cartItems?.length || 0}
              itemsTotal={itemsTotal}
              confirmationPlacement="top"
            />
          </Col>
        </Row>
      </Layout>

      <ButtonBackToTop />
    </>
  );
};

export default Cart;
